.Entete {
  background-color: #000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  font-size: 0.85rem;
  .logo {
    font-size: 1.4rem;
  }

  & > * {
    display: flex;
    padding: 0 1.25rem;
  }

  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .utilisateur {
      display: flex;
      align-items: center;
      cursor: pointer;
      .avatar {
        margin-left: 1.25rem;
        width: 2rem;
        height: 2rem;
      }
    }

    .btn-deconnexion {
      margin-left: 1rem;
      background-color: #900;
      padding: 5px;
      border: 1px solid #600;
      border-radius: 3px;
      box-shadow: 1px 1px 10px 1px #888;
      cursor: pointer;
    }
  }
}