.Appli {
  .ajoutRessource {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #333;
    &:hover {
      background-color: #444;
    }
  }
}